import cx from 'classnames';
import { CSSProperties } from 'react';
import { Button } from '../../components/button/button';
import { Modal } from '../../components/modal/modal';

import classes from './noticeModal.module.css';

const closeButtonStyle: CSSProperties = {
  position: 'absolute',
  right: '2%',
};

type Props = {
  visible: boolean;
  notice: string;
  onDismiss: () => void;
  closeable: boolean;
};

export const NoticeModal: React.FC<Props> = ({ visible, onDismiss, notice, closeable }) => {
  return (
    <Modal visible={visible} onDismiss={onDismiss} zIndex={1000000000}>
      <div
        className={classes['container']}
        // style={{ backgroundImage: `url(${bd})` }}
      >
        {closeable && (
          <Button title="close" onClick={onDismiss} customStyle={closeButtonStyle}>
            <div
              className={classes['closeButton']}
              style={
                {
                  // backgroundImage: `url(${close})`,
                }
              }
            ></div>
          </Button>
        )}

        <div className={classes['catBoxesWrapper']}>
          {/* <img src={catBoxes} alt="" className={classes['catBoxes']} /> */}
        </div>
        <div className={cx(classes['title'], 'pn-bold')}>{notice}</div>
        {!closeable && <div style={{ height: 'calc(var(--game-width) * 0.1)' }} />}

        {closeable && (
          <div className={classes['actionRow']}>
            <Button title="keep" onClick={onDismiss}>
              <div className={cx(classes['keepButton'], 'pn-bold')}>OK</div>
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
