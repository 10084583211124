import { Modal } from './modal';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';

import { Button } from '../button/button';
import { ASSETS, VOUCHER_IMAGES } from '../../utils/assetUtils';
import { VoucherItem, VoucherType } from '../../typings/voucher';
import classes from './basketModal.module.css';
import { useTouchOutside } from '../../hooks';

type Props = {
  visible: boolean;
  onDismiss: () => void;
};

const exitButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  top: `calc(var(--game-width) * -0.1)`,
};

const useVoucherButtonStyle: CSSProperties = {
  position: 'absolute',
  right: `calc(var(--game-width) * 0.018)`,
  bottom: `calc(var(--game-width) * 0.033)`,
};

const dummyVoucher: VoucherItem[] = [
  {
    id: '1',
    type: VoucherType.VOUCHER_100K,
    receiveDate: '12/04/2023',
  },
  {
    id: '1',
    type: VoucherType.VOUCHER_10K,
    receiveDate: '12/04/2023',
  },
  {
    id: '1',
    type: VoucherType.VOUCHER_50K,
    receiveDate: '12/04/2023',
  },
  {
    id: '1',
    type: VoucherType.VOUCHER_300K,
    receiveDate: '12/04/2023',
  },
  {
    id: '1',
    type: VoucherType.VOUCHER_3000K,
    receiveDate: '12/04/2023',
  },
  {
    id: '1',
    type: VoucherType.VOUCHER_3000K,
    receiveDate: '12/04/2023',
  },
  {
    id: '1',
    type: VoucherType.VOUCHER_3000K,
    receiveDate: '12/04/2023',
  },
  {
    id: '1',
    type: VoucherType.VOUCHER_0K,
    receiveDate: '12/04/2023',
  },
];

const filters = [
  'all',
  VoucherType.VOUCHER_3000K,
  VoucherType.VOUCHER_300K,
  VoucherType.VOUCHER_100K,
  VoucherType.VOUCHER_50K,
  VoucherType.VOUCHER_10K,
];

const filterTexts: { [key in string]: string } = {
  all: 'Tất cả',
  [VoucherType.VOUCHER_3000K]: 'E - Voucher 3.000.000 VNĐ',
  [VoucherType.VOUCHER_300K]: 'E - Voucher 300.000 VNĐ',
  [VoucherType.VOUCHER_100K]: 'E - Voucher 100.000 VNĐ',
  [VoucherType.VOUCHER_50K]: 'E - Voucher 50.000 VNĐ',
  [VoucherType.VOUCHER_10K]: 'E - Voucher 10.000 VNĐ',
};

export const BasketModal: React.FC<Props> = ({ visible, onDismiss }) => {
  const vouchers = dummyVoucher;
  const [currentFilter, setCurrentFilter] = useState('all');

  const [nodeRef, currentRefKey, setCurrentRefKey] = useTouchOutside();

  const displayVouchers = useMemo(() => {
    if (currentFilter === 'all') {
      return vouchers;
    }
    const newVouchers = vouchers.filter(voucher => voucher.type === currentFilter);
    return newVouchers;
  }, [vouchers, currentFilter]);

  const showingModal = useMemo(() => {
    return currentRefKey === 'FILTER_MODAL';
  }, [currentRefKey]);

  const renderFilterModal = useCallback(() => {
    return (
      <div
        className={cx(classes['filterModal'], showingModal ? classes['on'] : classes['off'])}
        ref={element => {
          (nodeRef as any).current = element;
        }}
      >
        {filters.map((filter, index) => {
          const isSelecting = currentFilter === filter;
          return (
            <div
              className={cx(classes['filterItem'], isSelecting ? 'pro-medium' : 'pro-light')}
              style={{
                backgroundColor: isSelecting ? '#9DC3FF' : '#ffffff',
                color: isSelecting ? 'white' : '#959595',
                borderBottom:
                  index === filters.length - 1
                    ? 'none'
                    : 'calc(var(--game-width) * 0.002) solid #9dc3ff',
              }}
              key={`filter_${index}`}
              onClick={() => {
                setCurrentFilter(filter);
              }}
            >
              {filterTexts[filter]}
            </div>
          );
        })}
      </div>
    );
  }, [currentFilter, nodeRef, showingModal]);

  const renderVoucher = useCallback((item: VoucherItem, index: number) => {
    return (
      <div className={classes['voucherItem']}>
        <div className={classes['logoWrapper']}>
          <div
            className={classes['logoImageWrapper']}
            style={{
              backgroundColor: index % 2 === 1 ? '#daeaff' : 'white',
            }}
          >
            <img src={VOUCHER_IMAGES[item.type]} alt="" />
          </div>
        </div>
        <div className={classes['infoWrapper']}>
          <div className={cx(classes['voucherName'], 'pro-medium')}>{VOUCHER_NAMES[item.type]}</div>
          <div className={cx(classes['voucherDate'], 'pro-light')}>Đã nhận: {item.receiveDate}</div>
        </div>
        <Button title="use_voucher" customStyle={useVoucherButtonStyle}>
          <div className={cx(classes['useVoucherButton'], 'pro-light')}>Sử dụng ngay</div>
        </Button>
      </div>
    );
  }, []);

  return (
    <Modal visible={visible} onDismiss={onDismiss}>
      <div
        className={classes['container']}
        style={{
          backgroundImage: `url(${ASSETS.SHORT_MODAL_BD})`,
        }}
      >
        <Button title="exit" onClick={onDismiss} customStyle={exitButtonStyle}>
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT})`,
            }}
          ></div>
        </Button>
        <div className={classes['header']}>
          <img src={ASSETS.BASKET_HEADER_BANNER} alt="" />
        </div>

        <div className={classes['filterWrapper']}>
          <div
            className={classes['filterButton']}
            onClick={() => {
              setCurrentRefKey('FILTER_MODAL');
            }}
          >
            <span className="pro-light">{filterTexts[currentFilter]}</span>
            <img src={ASSETS.ARROW} alt="" />
          </div>
          {renderFilterModal()}
        </div>

        <div className={classes['voucherList']}>
          {displayVouchers.map((voucher, index) => {
            return <div key={`voucher_${index}`}>{renderVoucher(voucher, index)}</div>;
          })}
        </div>
        <div className={classes['buttonRow']}>
          <Button title="instruction">
            <div
              className={cx(classes['instructionButton'], 'pro-bold')}
              style={{
                backgroundImage: `url(${ASSETS.ORANGE_BUTTON})`,
              }}
            >
              Hướng dẫn sử dụng
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const VOUCHER_NAMES = {
  [VoucherType.VOUCHER_0K]: '',
  [VoucherType.VOUCHER_10K]: 'Got it e- Voucher 10.000 VNĐ',
  [VoucherType.VOUCHER_50K]: 'Got it e- Voucher 50.000 VNĐ',
  [VoucherType.VOUCHER_100K]: 'Got it e- Voucher 100.000 VNĐ',
  [VoucherType.VOUCHER_300K]: 'Got it e- Voucher 300.000 VNĐ',
  [VoucherType.VOUCHER_3000K]: 'Got it e- Voucher 3.000.000 VNĐ',
};
