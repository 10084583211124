import { Modal } from './modal';
import { CSSProperties, useCallback, useState } from 'react';
import cx from 'classnames';

import { Button } from '../button/button';
import { ASSETS } from '../../utils/assetUtils';
import classes from './InstructionModal.module.css';
import eventEmitter from '../../utils/eventEmitter';
import { EMITTER_EVENTS } from '../../constants';

type Props = {
  visible: boolean;
  onDismiss: () => void;
};

enum InstructionTab {
  RULE = 'RULE',
  GUIDE = 'GUIDE',
}

const exitButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  top: `calc(var(--game-width) * -0.1)`,
};

const quests = [
  {
    icon: ASSETS.METHOD1_ICON,
    title: 'Định danh & đăng nhập lần đầu',
    des: 'Tối đa 6 lượt chơi',
  },
  {
    icon: ASSETS.METHOD2_ICON,
    title: 'Đăng nhập hàng ngày',
    des: 'Tối đa 1 lượt chơi/ ngày',
  },
];

export const InstructionModal: React.FC<Props> = ({ visible, onDismiss }) => {
  const [tab, setTab] = useState(InstructionTab.RULE);

  const renderRule = useCallback(() => {
    return (
      <div className={classes['ruleContainer']}>
        <div className={cx(classes['ruleTitle'], 'pro-light')}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </div>
        <Button title="view_rule">
          <div className={cx(classes['viewRuleButton'], 'pro-medium')}>Xem chi tiết thể lệ</div>
        </Button>
        <div>
          <img className={classes['ruleDeco']} src={ASSETS.INSTRUCTION_DECO} alt="" />
        </div>
      </div>
    );
  }, []);

  const renderGuide = useCallback(() => {
    return (
      <div className={classes['guideContainer']}>
        <div className={cx(classes['guideTitle'], 'pro-bold')}>Hướng dẫn cách chơi</div>
        <div className={cx(classes['guideDescription'], 'pro-light')}>
          Người chơi hoàn thành nhiệm vụ để lấy lượt chơi sau đó bấm vào nút “QUAY NGAY” để quay
          vòng quay may mắn và nhận giải thưởng.
        </div>
        <div className={classes['guideButtonWrapper']}>
          <div>
            <img className={classes['guideSpinButton']} src={ASSETS.SPIN_BUTTON} alt="" />
          </div>
        </div>
        <div className={cx(classes['guideTitle'], 'pro-bold')}>Hướng dẫn sử dụng quà</div>
        <div className={cx(classes['guideDescription'], 'pro-light')}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </div>
        <div className={classes['guideButtonWrapper']}>
          <Button title="viewDetail">
            <div className={cx(classes['guideViewDetail'], 'pro-medium')}>Xem chi tiết</div>
          </Button>
        </div>
        <div className={cx(classes['guideTitle'], 'pro-bold')}>Điều kiện nhận lượt chơi</div>
        <div className={cx(classes['guideDescription'], 'pro-light')}>
          Người chơi cần hoàn thành các nhiệm vụ dưới đây để nhận lượt chơi:
        </div>
        <div className={classes['guideQuestCardWrapper']}>
          {quests.map((quest, index) => {
            return (
              <div className={classes['guideQuestCard']} key={`quest_${index}`}>
                <div className={classes['guideIconWrapper']}>
                  <img
                    src={quest.icon}
                    alt=""
                    style={{
                      height: index === 0 ? '80%' : '99%',
                    }}
                  />
                </div>
                <div className={classes['guideContentWrapper']}>
                  <div className="pro-bold">{quest.title}</div>
                  <div className="pro-light">{quest.des}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes['guideButtonRow']}>
          <Button
            title="viewDetail"
            onClick={() => {
              onDismiss();
              eventEmitter.emit(EMITTER_EVENTS.VIEW_DETAIL_MISSION);
            }}
          >
            <div
              className={cx(classes['guideViewDetailMissionButton'], 'pro-bold')}
              style={{ backgroundImage: `url(${ASSETS.ORANGE_BUTTON})` }}
            >
              Chi tiết nhiệm vụ
            </div>
          </Button>
        </div>
      </div>
    );
  }, []);

  return (
    <Modal visible={visible} onDismiss={onDismiss}>
      <div
        className={classes['container']}
        style={{
          backgroundImage: `url(${ASSETS.SHORT_MODAL_BD})`,
        }}
      >
        <Button title="exit" onClick={onDismiss} customStyle={exitButtonStyle}>
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT})`,
            }}
          ></div>
        </Button>
        <div className={classes['header']}>
          <img src={ASSETS.INSTRUCTION_HEADER_BANNER} alt="" />
        </div>

        <div className={cx(classes['tabbar'], 'pro-medium')}>
          <div
            className={cx(
              classes['tabHighlight'],
              tab === InstructionTab.GUIDE && classes['tabTranslated']
            )}
          ></div>
          <div
            className={cx(
              classes['tab'],
              classes['tab1'],
              tab === InstructionTab.RULE && classes['tabFocused']
            )}
            onClick={() => {
              setTab(InstructionTab.RULE);
            }}
          >
            Thể lệ
          </div>
          <div
            className={cx(
              classes['tab'],
              classes['tab2'],
              tab === InstructionTab.GUIDE && classes['tabFocused']
            )}
            onClick={() => {
              setTab(InstructionTab.GUIDE);
            }}
          >
            Hướng dẫn chơi
          </div>
        </div>
        <div className={classes['tabContent']}>
          {tab === InstructionTab.RULE ? renderRule() : renderGuide()}
        </div>
      </div>
    </Modal>
  );
};
