import { useCallback, useEffect, useState } from 'react';
import { Button } from '../../components/button/button';
import { ASSETS } from '../../utils/assetUtils';
import { AudioType, soundUtils } from '../../utils/soundUtils';
import {
  getMusicSetting,
  getSoundFXSetting,
  setMusicSetting,
  setSoundFXSetting,
} from '../../utils/storage';
import classes from './fixedTopContent.module.css';

type Props = {
  //
};

export const FixedTopContent: React.FC<Props> = () => {
  const [isSoundFxOn, setIsSoundFxOn] = useState(false);
  const [isMusicOn, setIsMusicOn] = useState(false);

  useEffect(() => {
    const isEnableMusic = getMusicSetting();
    setIsMusicOn(isEnableMusic);
    const isEnableSoundFx = getSoundFXSetting();
    setIsSoundFxOn(isEnableSoundFx);
  }, []);

  const onUpdateMusicSetting = useCallback(async (on: boolean) => {
    if (soundUtils.mute(AudioType.MUSIC, !on)) {
      soundUtils.setMusicEnable(on);
      setMusicSetting(on);
      setIsMusicOn(on);
    }
  }, []);

  const onUpdateSoundFXSetting = useCallback(async (on: boolean) => {
    if (soundUtils.mute(AudioType.SOUND_FX, !on)) {
      soundUtils.setSoundFxEnable(on);
      setSoundFXSetting(on);
      setIsSoundFxOn(on);
    }
  }, []);

  const onUpdateSound = useCallback(() => {
    const currentState = getMusicSetting();
    const newState = !currentState;
    onUpdateMusicSetting(newState);
    onUpdateSoundFXSetting(newState);
  }, [onUpdateMusicSetting, onUpdateSoundFXSetting]);

  return (
    <>
      {/* <div className={classes['worldSpeaker']}>
        <img src={ASSETS.SPEAKER_ICON} alt="" />
        <img src={ASSETS.STROKE} alt="" />
        <div className={classes['noticeWrapper']}>
          <div className={cx(classes['notice'], 'pro-light')}>
            Quay số thả ga, rinh quà liền tay
          </div>
        </div>
      </div> */}
      <div className={classes['actionButtons1']}>
        <Button
          title="share"
          onClick={() => {
            const shareUrl = `https://shinhan-ls-demo.pages.dev`;
            const url = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&display=iframe`;
            (window as Window).location = url;
          }}
        >
          <div
            className={classes['button']}
            style={{
              backgroundImage: `url(${ASSETS.SHARE_BUTTON})`,
            }}
          ></div>
        </Button>
      </div>
      <div className={classes['actionButtons']}>
        <Button title="sound" onClick={onUpdateSound}>
          <div
            className={classes['button']}
            style={{
              backgroundImage: `url(${isMusicOn ? ASSETS.UNMUTE_BUTTON : ASSETS.MUTE_BUTTON})`,
            }}
          ></div>
        </Button>
      </div>
    </>
  );
};
