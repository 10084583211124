export const USER_TOKEN_KEY = '@userToken';
export const USER_TOKEN_HEADER = 'authorization';

export const UI_RATIO = 0.56;
export const CANVAS_RATIO = 414 / 621;

export const EMITTER_EVENTS = {
  SHOW_NOTICE: 'app::showNotice',
  START_SPIN: 'app::startSpin',
  SHOW_REWARD: 'app::showReward',
  SHOW_BASKET: 'app:showBasket',
  VIEW_DETAIL_MISSION: 'app::viewDetailMission',
  UPDATE_NUMBER_OF_TICKETS: 'app:updateNumberOfTickets',
};
