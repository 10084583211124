import { createTypedHooks } from 'easy-peasy';
import debounce from 'lodash.debounce';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { StoreModel } from './store';

const typedHooks = createTypedHooks<StoreModel>();

const useStoreActions = typedHooks.useStoreActions;
const useStoreState = typedHooks.useStoreState;

export const useTouchOutside = (): [
  MutableRefObject<HTMLDivElement | undefined>,
  string,
  (key: string) => void
] => {
  const nodeRef = useRef<HTMLDivElement>();
  const [currentRefKey, setCurrentRefKey] = useState('');

  useEffect(() => {
    const clickEvent = (() => {
      if ('ontouchstart' in document.documentElement === true) {
        return 'touchstart';
      } else {
        return 'mousedown';
      }
    })();

    const handleTouch = debounce((event: TouchEvent | MouseEvent) => {
      if (!nodeRef.current) {
        setCurrentRefKey('');
        return;
      }
      const target = event.target as any;
      if (target === nodeRef.current || nodeRef.current?.contains(target)) {
        return;
      } else {
        setCurrentRefKey('');
        nodeRef.current = undefined;
      }
    }, 50);

    window?.document?.addEventListener(clickEvent, handleTouch);
    return () => {
      window?.document?.removeEventListener(clickEvent, handleTouch);
    };
  }, []);

  return [nodeRef, currentRefKey, setCurrentRefKey];
};
