import { Modal } from './modal';
import { CSSProperties, useCallback, useEffect } from 'react';
import cx from 'classnames';

import { Button } from '../button/button';
import { ASSETS, VOUCHER_IMAGES } from '../../utils/assetUtils';
import classes from './RewardModal.module.css';
import { VoucherItem, VoucherType } from '../../typings/voucher';
import eventEmitter from '../../utils/eventEmitter';
import { EMITTER_EVENTS } from '../../constants';
import { SoundFxId, soundUtils } from '../../utils/soundUtils';

type Props = {
  reward?: VoucherItem;
  visible: boolean;
  onDismiss: () => void;
};

const exitButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  top: `calc(var(--game-width) * -0.14)`,
};

const dummyVoucher: VoucherItem = {
  id: '1',
  receiveDate: '12/04/2023',
  type: VoucherType.VOUCHER_10K,
};

export const RewardModal: React.FC<Props> = ({ reward, visible, onDismiss }) => {
  // const reward: VoucherItem | undefined = dummyVoucher;

  useEffect(() => {
    if (visible) {
      if (reward?.type !== VoucherType.VOUCHER_0K) {
        soundUtils.play(SoundFxId.REWARD);
      } else {
        soundUtils.play(SoundFxId.LUCKY_WISH);
      }
    }
  }, [visible, reward]);

  const renderLuckyWish = useCallback(() => {
    return (
      <div className={classes['luckyWishWrapper']}>
        <div className={cx(classes['luckyWishTitle'], 'pro-light')}>
          Quý khách nhận được lời chúc
        </div>
        <div className={classes['luckyWishContent']}>
          <img src={ASSETS.TRASH_BANNER} alt="" />
          <div className="pro-medium">Chúc bạn luôn vui vẻ tràn ngập tiếng cười</div>
        </div>
      </div>
    );
  }, []);

  const renderReward = useCallback(() => {
    if (!reward) return null;

    return (
      <div className={classes['rewardWrapper']}>
        <div className={cx(classes['rewardTitle'], 'pro-light')}>Quý khách đã trúng thưởng</div>
        <div>
          <img className={classes['rewardIcon']} src={VOUCHER_IMAGES[reward.type]} alt="" />
        </div>
        <div className={cx(classes['rewardName'], 'pro-bold')}>E- Voucher</div>
        <div className={cx(classes['rewardPrize'], 'pro-bold')}>
          trị giá {VOUCHER_VALUES[reward.type]}
        </div>
      </div>
    );
  }, [reward]);

  return (
    <Modal visible={visible} onDismiss={onDismiss} dismissOnBackdropClick={false}>
      <div
        className={classes['container']}
        style={{
          backgroundImage: `url(${ASSETS.REWARD_MODAL_BD})`,
        }}
      >
        <Button title="exit" onClick={onDismiss} customStyle={exitButtonStyle}>
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT})`,
            }}
          ></div>
        </Button>
        <div className={classes['header']}>
          <img src={ASSETS.REWARD_HEADER_BANNER} alt="" />
        </div>
        {reward && reward.type === VoucherType.VOUCHER_0K ? renderLuckyWish() : renderReward()}
        {reward && reward.type !== VoucherType.VOUCHER_0K && (
          <Button
            title="viewReward"
            customStyle={{
              position: 'absolute',
              bottom: `calc(var(--game-width) * -0.215)`,
            }}
            onClick={() => {
              onDismiss();
              eventEmitter.emit(EMITTER_EVENTS.SHOW_BASKET);
            }}
          >
            <div
              className={cx(classes['viewRewardButton'], 'pn-bold')}
              style={{ backgroundImage: `url(${ASSETS.BLUE_BUTTON})` }}
            >
              <img src={ASSETS.GIFT_ICON} alt="" />
              <span className="pro-semibold">Xem giỏ quà</span>
            </div>
          </Button>
        )}
      </div>
    </Modal>
  );
};

const VOUCHER_VALUES = {
  [VoucherType.VOUCHER_0K]: '',
  [VoucherType.VOUCHER_10K]: '10.000 VNĐ',
  [VoucherType.VOUCHER_50K]: '50.000 VNĐ',
  [VoucherType.VOUCHER_100K]: '100.000 VNĐ',
  [VoucherType.VOUCHER_300K]: '300.000 VNĐ',
  [VoucherType.VOUCHER_3000K]: '3.000.000 VNĐ',
};
